<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.cog">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Setting</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this setting?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>

				<ti-form-layout :title="setting.group">
					<template>
						<v-col cols="12" lg="6" v-if="setting.setting_type === 'text'">
							<v-text-field v-model="setting.setting_value" :label="setting.setting_name"></v-text-field>
						</v-col>
						<v-col cols="12" lg="6" v-if="setting.setting_type === 'select'">
							<v-select v-model="setting.setting_value" :label="setting.setting_name" :items="setting.setting_options"></v-select>
						</v-col>
						<v-col cols="12" lg="6" v-if="setting.setting_type === 'boolean'">
							<v-checkbox v-model="setting.setting_value" :label="setting.setting_name"></v-checkbox>
						</v-col>

						<v-col cols="12">
							<p>{{ setting.setting_description }}</p>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {sync} from "vuex-pathify";
import models from '@/models'

export default {

	name: "SettingsEdit",
	props: ['id'],
	computed: {
		setting: sync('settings/setting'),
		isEdit() {
			return this.$route.meta.edit
		},
	},
	data: () => ({
		valid: false,
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('settings/getSetting!', this.id)
			} else {
				this.setting = models.Setting()
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				this.$api.settings.update(this.id, this.setting)
					.then(response => {
						this.$toast.add('Setting updated successfully', 'success')
						this.$route.meta.disableWarn = true;
						this.$router.push({name: 'Settings'})
					})
					.catch(error => {
						console.log(error.response);
						this.$toast.handleResponseErrors(this, error)
					})
			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			alert('delete method not yet implemented.')
		},
		resetPassword() {
			this.resetPasswordLoading = true;
			this.$api.settings.reset_password(this.id)
				.then(() => this.$toast.add('Password reset email sent', 'success'))
				.catch((error) => this.$toast.handleResponseErrors(this, error))
				.finally(() => this.resetPasswordLoading = false)
		}
	}
}
</script>